<template>
  <div class="card border">
    <div class="card-header d-flex justify-content-between" style="height: 56px">
      <div style="font-size: 14px">{{ $t('bookkeeping.bookkeepingManagement.sendingConfig.header') }}</div>
      <el-dropdown trigger="click" type="bottom" @command="handleCommand">
        <Button type="text" class="p-1 text-typography-primary secondary-hover-btn button">
          <KebabIcon />
        </Button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="COMMAND_TYPES.EDIT_BILLINGS_SENDING">
            <p class="text-typography-primary">
              <EditIcon />
              {{ translate('actions.editBillingSending') }}
            </p>
          </el-dropdown-item>
          <el-dropdown-item :command="COMMAND_TYPES.EDIT_PAYMENTS_SENDING">
            <p class="text-typography-primary">
              <EditIcon />
              {{ translate('actions.editPaymentSending') }}
            </p>
          </el-dropdown-item>
          <el-dropdown-item :command="COMMAND_TYPES.EDIT_DOCUMENTS_SENDING">
            <p class="text-typography-primary">
              <EditIcon />
              {{ translate('actions.editDocumentSending') }}
            </p>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-loading="loading" class="card-body row g-0">
      <Table v-loading="loading" :columns="columns" :data="tableData" :hover="false" rounded>
        <template #cell-dataType="{ rowData: { dataType } }">
          <p class="fw-bold">{{ dataType }}</p>
        </template>
        <template #cell-email="{ rowData: { email } }">
          {{ email }}
        </template>
        <template #cell-dataSendingConfig="{ rowData: { dataSendingConfig } }">
          <div class="d-flex align-items-center">
            <AutomaticIcon v-if="dataSendingConfig.autoSend" class="ms-1" width="14px" height="12px" />
            <p>{{ dataSendingConfig.text }}</p>
          </div>
        </template>
        <template #cell-latestSending="{ rowData: { latestSending } }">
          {{ formatDate(latestSending) }}
        </template>
      </Table>
    </div>
    <ConfigSendingFormBillings
      v-if="showConfigSendingFormBillings"
      :config="config"
      @close="showConfigSendingFormBillings = false"
    />
    <ConfigSendingFormPayments
      v-if="showConfigSendingFormPayments"
      :config="config"
      @close="showConfigSendingFormPayments = false"
    />
    <ConfigSendingFormDocuments
      v-if="showConfigSendingFormDocuments"
      :config="config"
      @close="showConfigSendingFormDocuments = false"
    />
  </div>
</template>

<script>
import { KebabIcon, EditIcon, AutomaticIcon } from '@/assets/icons';
import { options } from '@/locale/dateConfig';
import { Button, Table } from '@/modules/core';
import ConfigSendingFormBillings from '@/modules/bookkeeping/components/ConfigSendingFormBillings';
import ConfigSendingFormPayments from '@/modules/bookkeeping/components/ConfigSendingFormPayments';
import ConfigSendingFormDocuments from '@/modules/bookkeeping/components/ConfigSendingFormDocuments';
import { isNil, omit } from 'ramda';

const COMMAND_TYPES = {
  EDIT_BILLINGS_SENDING: 'editBillingsSending',
  EDIT_PAYMENTS_SENDING: 'editPaymentsSending',
  EDIT_DOCUMENTS_SENDING: 'editDocumentsSending',
};

const TABLE_HEADERS = {
  DATA_TYPE: 'dataType',
  EMAIL: 'email',
  DATA_SENDING_CONFIG: 'dataSendingConfig',
  LATEST_SENDING: 'latestSending',
};

export default {
  components: {
    KebabIcon,
    EditIcon,
    AutomaticIcon,
    Button,
    Table,
    ConfigSendingFormBillings,
    ConfigSendingFormPayments,
    ConfigSendingFormDocuments,
  },
  props: {
    config: { type: Object, required: true },
    latestBillingsSending: { type: Date, default: null },
    latestPaymentsSending: { type: Date, default: null },
    latestDocumentsSending: { type: Date, default: null },
    loading: { type: Boolean, required: true },
  },
  data() {
    return {
      COMMAND_TYPES,
      TABLE_HEADERS,
      showConfigSendingFormBillings: false,
      showConfigSendingFormPayments: false,
      showConfigSendingFormDocuments: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translateTableHeader(TABLE_HEADERS.DATA_TYPE),
          key: TABLE_HEADERS.DATA_TYPE,
          width: '128px',
        },
        {
          header: this.translateTableHeader(TABLE_HEADERS.EMAIL),
          key: TABLE_HEADERS.EMAIL,
          width: '164px',
        },
        {
          header: this.translateTableHeader(TABLE_HEADERS.DATA_SENDING_CONFIG),
          key: TABLE_HEADERS.DATA_SENDING_CONFIG,
          width: '122px',
        },
        {
          header: this.translateTableHeader(TABLE_HEADERS.LATEST_SENDING),
          key: TABLE_HEADERS.LATEST_SENDING,
          width: '100px',
        },
      ];
    },
    tableData() {
      return [
        {
          [TABLE_HEADERS.DATA_TYPE]: this.translateDataType('billingSending'),
          [TABLE_HEADERS.EMAIL]: this.config.billingSending ? this.config.billingSending.email : '-',
          [TABLE_HEADERS.DATA_SENDING_CONFIG]: {
            text: this.getDataSendingConfig(this.config.billingSending, false),
            autoSend: this.config.billingSending ? this.config.billingSending.autoSend : false,
          },
          [TABLE_HEADERS.LATEST_SENDING]: this.latestBillingsSending,
        },
        {
          [TABLE_HEADERS.DATA_TYPE]: this.translateDataType('paymentSending'),
          [TABLE_HEADERS.EMAIL]: this.config.paymentSending ? this.config.paymentSending.email : '-',
          [TABLE_HEADERS.DATA_SENDING_CONFIG]: {
            text: this.getDataSendingConfig(this.config.paymentSending, true),
            autoSend: this.config.paymentSending ? this.config.paymentSending.autoSend : false,
          },
          [TABLE_HEADERS.LATEST_SENDING]: this.latestPaymentsSending,
        },
        {
          [TABLE_HEADERS.DATA_TYPE]: this.translateDataType('documentSending'),
          [TABLE_HEADERS.EMAIL]: this.config.documentSending ? this.config.documentSending.email : '-',
          [TABLE_HEADERS.DATA_SENDING_CONFIG]: {
            text: this.getDataSendingConfig(this.config.documentSending, false),
            autoSend: this.config.documentSending ? this.config.documentSending.autoSend : false,
          },
          [TABLE_HEADERS.LATEST_SENDING]: this.latestDocumentsSending,
        },
      ];
    },
    missingCount() {
      return !this.loading ? this.missingBillingsCount : '-';
    },
    unsentCount() {
      return !this.loading ? this.unsentDocumentsCount : '-';
    },
  },
  methods: {
    translate(key) {
      return this.$t(`bookkeeping.bookkeepingManagement.sendingConfig.${key}`);
    },
    formatDate(ms) {
      return !this.loading && ms
        ? new Date(ms).toLocaleDateString(this.$i18n.locale, omit(['weekday'], options.full))
        : '-';
    },
    translateTableHeader(columnKey) {
      return this.$t(`bookkeeping.bookkeepingManagement.sendingConfig.table.headers.${columnKey}`);
    },
    translateDataType(columnValue) {
      return this.$t(`bookkeeping.bookkeepingManagement.sendingConfig.table.${columnValue}`);
    },
    getDataSendingConfig(config, isPayment) {
      if (isNil(config)) return '-';
      const { email, autoSend } = config;
      if (isNil(email)) {
        return '-';
      } else {
        if (isPayment && autoSend)
          return this.$t('bookkeeping.bookkeepingManagement.sendingConfig.table.dataSendingConfig.monthly');
        if (isPayment && !autoSend)
          return this.$t('bookkeeping.bookkeepingManagement.sendingConfig.table.dataSendingConfig.manual');
        if (!isPayment && autoSend)
          return this.$t('bookkeeping.bookkeepingManagement.sendingConfig.table.dataSendingConfig.afterRecord');
        return this.$t('bookkeeping.bookkeepingManagement.sendingConfig.table.dataSendingConfig.manual');
      }
    },
    handleCommand(command) {
      switch (command) {
        case COMMAND_TYPES.EDIT_BILLINGS_SENDING:
          this.showConfigSendingFormBillings = true;
          break;
        case COMMAND_TYPES.EDIT_PAYMENTS_SENDING:
          this.showConfigSendingFormPayments = true;
          break;
        case COMMAND_TYPES.EDIT_DOCUMENTS_SENDING:
          this.showConfigSendingFormDocuments = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.secondary-hover-btn {
  &:hover {
    background: $secondary;
  }
}

.button {
  height: 2rem;
  width: 2rem;
}

:deep(.el-button) {
  border: 1px solid;
}

.cell {
  height: 14px;
  width: 164px;
}
</style>
