<template>
  <div>
    <Table
      :columns="columns"
      :data="tableData"
      show-index
      rounded
      border
      @row-click="paymentId = tableData[$event].id"
    />
    <EventMapModal v-if="paymentId" :activity="{ id: paymentId, type: 'payment' }" @close="paymentId = null" />
  </div>
</template>

<script>
import { DateTime } from 'luxon';

import { Table } from '@/modules/core';
import { EventMapModal } from '@/modules/eventMapModal';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatDate } from '../formatters';

const TABLE_HEADERS = {
  DUE_DATE: 'dueDate',
  SUPPLIER: 'supplier',
  BILLING_COUNT: 'billingCount',
  DATE_PERIOD: 'datePeriod',
  TOTAL_AMOUNT: 'totalAmount',
};

export default {
  components: { Table, EventMapModal },
  props: {
    payments: { type: Array, required: true },
  },
  setup() {
    const { formatToCurrency } = useCurrency();
    return { formatToCurrency };
  },
  data() {
    return {
      paymentId: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translateTableHeader('dueDate'),
          key: TABLE_HEADERS.DUE_DATE,
        },
        {
          header: this.translateTableHeader('supplier'),
          key: TABLE_HEADERS.SUPPLIER,
        },
        {
          header: this.translateTableHeader('billingCount'),
          key: TABLE_HEADERS.BILLING_COUNT,
        },
        {
          header: this.translateTableHeader('datePeriod'),
          key: TABLE_HEADERS.DATE_PERIOD,
        },
        {
          header: this.translateTableHeader('totalAmount'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
        },
      ];
    },
    tableData() {
      return this.payments.map(({ id, billingLinks, date, supplier, amount, isRefund }) => {
        const dateCount = billingLinks.reduce((isoMonthGroupsCount, { billing }) => {
          if (billing) {
            const isoMonthDate = DateTime.fromJSDate(new Date(billing.date)).toFormat('yyyy-LL');
            if (!isoMonthGroupsCount[isoMonthDate]) isoMonthGroupsCount[isoMonthDate] = 0;
            isoMonthGroupsCount[isoMonthDate] += 1;
          }
          return isoMonthGroupsCount;
        }, {});

        let maxDate = null;
        Object.entries(dateCount).forEach(([isoMonthDate, count]) => {
          if (!maxDate) {
            maxDate = isoMonthDate;
            return;
          }
          if (count > dateCount[maxDate]) {
            maxDate = isoMonthDate;
            return;
          }
        });

        return {
          id,
          [TABLE_HEADERS.DUE_DATE]: this.formatDate(date),
          [TABLE_HEADERS.SUPPLIER]: supplier.name,
          [TABLE_HEADERS.BILLING_COUNT]: billingLinks.length,
          [TABLE_HEADERS.DATE_PERIOD]: maxDate
            ? new Date(maxDate).toLocaleDateString(this.$i18n.locale, {
                month: 'short',
                year: 'numeric',
              })
            : '-',
          [TABLE_HEADERS.TOTAL_AMOUNT]: this.formatMoney(amount * (isRefund ? -1 : 1)),
        };
      });
    },
  },
  methods: {
    formatDate,
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },

    handleRowClick(rowIndex) {
      this.displayedDocument = this.filteredTableData[rowIndex].documentId;
    },
    translateTableHeader(columnKey) {
      return this.$t(`bookkeeping.bookkeepingManagement.unsentPayments.table.headers.${columnKey}`);
    },
  },
};
</script>
