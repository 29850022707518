<template>
  <el-dialog
    visible
    :show-close="false"
    custom-class="rounded document-sending-modal"
    width="600px"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h3>{{ $t('bookkeeping.send') }}</h3>
        <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="p-4">
      <el-form ref="form" :model="formModel" :show-message="false" @submit.native.prevent="handleSendButtonClick">
        <h3 class="mb-2">{{ $t(`bookkeeping.sendModal.documentsToSend.header.${type}`) }}</h3>
        <div class="d-flex align-items-center gap-1">
          <template v-if="toDate">{{
            $tc(`bookkeeping.sendModal.documentsToSend.description.${type}`, sendCount, {
              fromDate: formatDate(fromDate),
              toDate: formatDate(toDate),
              count: sendCount,
            })
          }}</template>
          <div v-else class="d-flex align-items-center gap-1">
            {{
              $tc(`bookkeeping.sendModal.documentsToSend.descriptionWithInput.${type}`, sendCount, {
                fromDate: formatDate(fromDate),
                count: sendCount,
              })
            }}
            <el-form-item prop="toDate" class="mb-0" required style="width: 125px">
              <el-date-picker
                v-model="formModel.toDate"
                size="small"
                type="date"
                :format="$direction === 'ltr' ? 'MM/dd/yyyy' : 'dd.MM.yyyy'"
                class="w-auto"
                :clearable="false"
                :picker-options="pickerOptions"
              />
            </el-form-item>
          </div>
          ({{ $t('bookkeeping.sendModal.documentsToSend.included') }})
        </div>

        <div class="notification d-flex align-items-center mb-5">
          <NoticeIcon class="p-1" />
          <p>{{ $t('bookkeeping.sendModal.documentsToSend.unknownType') }}</p>
        </div>

        <h3 class="mb-2">{{ $t(`bookkeeping.sendModal.sendTo.header.${type}`) }}</h3>
        <template v-if="resend">
          <el-radio-group v-model="isOtherEmailSelected" class="d-flex flex-column">
            <el-radio :label="false" class="mb-2 m-0">
              <span class="d-inline-flex align-items-center w-100">
                <p>{{ sendEmail }}</p>
              </span>
            </el-radio>
            <el-radio :label="true" class="mb-2 m-0">
              <span class="d-inline-flex align-items-center w-100">
                <p class="text-typography-primary" :class="$direction === 'ltr' ? 'me-2' : 'ms-2'">
                  {{ $t('bookkeeping.sendModal.sendTo.other') }}
                </p>
                <el-form-item
                  :required="isOtherEmailSelected"
                  prop="otherEmail"
                  class="m-0 w-50"
                  :rules="{ type: 'email' }"
                >
                  <el-input
                    v-model="formModel.otherEmail"
                    size="small"
                    :disabled="!isOtherEmailSelected"
                    :placeholder="$t('bookkeeping.sendModal.sendTo.emailPlaceholder')"
                  />
                </el-form-item>
              </span>
            </el-radio>
          </el-radio-group>
        </template>
        <p v-else-if="sendEmail">{{ sendEmail }}</p>
        <div v-else class="d-flex align-items-center">
          <div class="shop-icon-wrapper bg-secondary rounded-pill mx-1">
            <ShopIcon class="shop-icon text-typography-secondary" />
          </div>
          {{ bookkeeper?.name }}
        </div>
      </el-form>
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="$emit('close')">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="handleSendButtonClick">
          {{ $t('bookkeeping.sendModal.execution') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import { DateTime } from 'luxon';

import { Button } from '@/modules/core';
import { CloseIcon, NoticeIcon, ShopIcon } from '@/assets/icons';
import { useBusinessByNames } from '@/modules/business/compositions/business';

import { BATCH_TYPE } from '../batch';
import { formatDate } from '../formatters';

export default {
  components: { Button, CloseIcon, NoticeIcon, ShopIcon },
  props: {
    docs: { type: Array, required: true },
    type: { type: String, required: true, validator: (value) => Object.values(BATCH_TYPE).indexOf(value) !== -1 },
    sendEmail: { type: String, default: null },
    fromDate: { type: Number, required: true },
    toDate: { type: Number, default: null },
    resend: { type: Boolean, default: false },
    sendToBookkeeperId: { type: String, default: null },
  },
  setup(props) {
    const previousMonthLastDay = DateTime.local().startOf('month').minus({ day: 1 }).endOf('day').toJSDate();

    const { result: businesses } = useBusinessByNames({
      businessIds: computed(() => (props.sendToBookkeeperId ? [props.sendToBookkeeperId] : [])),
    });

    return {
      formatDate,
      isOtherEmailSelected: ref(false),
      formModel: reactive({
        toDate: props.fromDate > previousMonthLastDay ? null : previousMonthLastDay,
        otherEmail: null,
      }),
      bookkeeper: computed(() => businesses.value[0]),
    };
  },
  computed: {
    pickerOptions() {
      const localFromDate = DateTime.fromMillis(this.fromDate);
      const utcOffset = localFromDate.offset;
      const utcFromDate = localFromDate.minus({ minutes: utcOffset }).toJSDate();
      return { disabledDate: (date) => date < utcFromDate };
    },
    sendCount() {
      return this.docs.filter(({ issueDate, date }) => {
        const docDate = this.type === BATCH_TYPE.DOCUMENT ? issueDate : date;
        return new Date(docDate) <= DateTime.fromJSDate(this.formModel.toDate).endOf('day').toJSDate();
      }).length;
    },
  },
  methods: {
    handleSendButtonClick() {
      if (!this.toDate || this.resend) {
        this.$refs.form.validate((valid) => {
          if (valid)
            this.$emit('send', {
              toDate: DateTime.fromJSDate(this.formModel.toDate).endOf('day').toJSDate(),
              email: this.formModel.otherEmail,
            });
        });
      } else this.$emit('send');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

.notification {
  color: $typography-secondary;
}

:deep(.document-sending-modal) {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer,
  .el-input__inner {
    padding: 0;
    color: $typography-primary;
  }
}

.shop-icon-wrapper {
  height: 32px;
  width: 32px;
  margin: 0 !important;
  margin-inline-end: 0.5rem !important;
  .shop-icon {
    display: block;
    margin: auto;
    margin-top: 4px;
  }
}
</style>
